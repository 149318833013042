<template>
  <div>
    <monitors />
  </div>
</template>

<script>
import Monitors from '@/components/Devices/Monitors/Monitors.vue'

export default {
  components: { Monitors },
  name: 'Monitor',
}
</script>
