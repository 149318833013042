<template>
  <div>
    <v-dialog
      v-model="isActive"
      persistent
      transition="dialog-transition"
      width="325"
      height="180"
    >
      <span class="d-none">{{ change }}</span>

      <v-card class="rounded-lg">
        <v-card-title>
          <v-row>
            <v-col cols="11" class="px-0">
              <h6 class="text-subtitle-1 font-weight-bold text-center">
                <span class="font-work-sans ml-5"
                  >{{ edit ? 'Modificar ' : 'Crear ' }}Monitor</span
                >
              </h6>
            </v-col>
            <v-col cols="1" class="pa-0">
              <v-btn color="primary" icon @click="$emit('dismiss')"
                ><v-icon small>fa-times</v-icon></v-btn
              >
            </v-col>
          </v-row>
        </v-card-title>

        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <label for="" class="ml-4 black--text font-weight-medium"
              >Número</label
            >
            <v-text-field
              placeholder="Número"
              color="primary"
              rounded
              :rules="[rules.required, rules.number]"
              prepend-inner-icon="fa-tv"
              required
              outlined
              class="mt-2 mb-n3"
              v-model="monitor.number"
            ></v-text-field>

            <label for="" class="ml-4 black--text font-weight-medium"
              >Descripción</label
            >
            <v-text-field
              placeholder="Descripción"
              color="primary"
              rounded
              :rules="[rules.required, rules.alfanumeric]"
              prepend-inner-icon="fa-file-alt"
              required
              outlined
              class="mt-2 mb-n3 font-quicksand"
              v-model="monitor.description"
            ></v-text-field>
          </v-form>
        </v-card-text>

        <v-card-actions class="mt-n4">
          <v-btn
            color="primary"
            outlined
            class="rounded-lg px-4"
            @click="
              () => {
                $refs.form.reset()
                $emit('dismiss')
              }
            "
          >
            <v-icon small>fa-times</v-icon>
            <span class="text-capitalize ml-2 font-weight-bold">Cancelar</span>
          </v-btn>
          <v-spacer></v-spacer>

          <v-btn
            color="success"
            class="rounded-lg elevation-0 px-4"
            @click="submit"
            :loading="loading"
          >
            <v-icon small>fa-check</v-icon>
            <span class="text-capitalize ml-2 font-weight-bold">Aceptar</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <dialog-edit
      :item="item"
      :dialog="dialogEdit"
      @dismiss="
        () => {
          dialogEdit = false
        }
      "
      @accept="activeAlerts()"
    />
  </div>
</template>

<script>
import Repository from '@/repositories/RepositoryFactory'
import DialogEdit from '@/components/DialogEdit.vue'
import Swal from 'sweetalert2'
const MonitorRepository = Repository.get('monitors')

export default {
  components: { DialogEdit },
  name: 'FormDialogMonitor',
  props: {
    monitor: { type: Object, default: { number: '', description: '' } },
    dialog: { type: Boolean, default: false },
    edit: { type: Boolean, default: false },
  },
  data: () => ({
    isActive: false,
    valid: false,
    loading: false,
    dialogEdit: false,
    item: {},
    rules: {
      required: (value) => !!value || 'Campo requerido.',
      counter: (value) =>
        value?.length >= 8 || 'Contraseña mínima de 8 carácteres.',
      alfanumeric: (value) => {
        const pattern = /^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g
        return !pattern.test(value) || 'Campo inválido.'
      },
      email: (value) => {
        const pattern = /.+@.+/
        return pattern.test(value) || 'Email inválido.'
      },
      number: (value) => {
        const pattern = /[^0-9]/
        return (
          !pattern.test(value) ||
          'Este campo no admite letras ni caracteres especiales.'
        )
      },
    },
  }),
  computed: {
    change: async function () {
      try {
        this.isActive = await this.dialog
      } catch (error) {
        console.log(error)
      }
    },
  },
  methods: {
    submit: function () {
      this.$refs.form.validate()

      setTimeout(() => {
        if (this.valid) {
          if (!this.edit) this.activeAlerts()
          else {
            this.item = {
              type: 'monitor',
              id: this.monitor.number,
              name: this.monitor.description,
            }
            this.dialogEdit = true
          }
        }
      })
    },
    activeAlerts: async function () {
      this.dialogEdit = false

      const data = {
        type: 'success',
        title: 'Petición exitosa',
        text: this.edit
          ? 'Monitor actualizado con éxito'
          : 'Monitor registrado con éxito',
      }

      this.loading = true

      try {
        this.edit
          ? await MonitorRepository.update(this.monitor, this.monitor.id)
          : await MonitorRepository.create(this.monitor)

        this.$store.dispatch('alerts/activeAlerts', data)
        this.$refs.form.reset()
        this.$emit('dismiss')
      } catch (error) {
        const response = error.response
          ? error.response.data.message ||
            error.response.data.errors?.reduce((acc, cur) => ({
              msg: acc.msg + ', ' + cur.msg,
            })).msg
          : error

        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: response,
        })
      }

      this.loading = false
    },
  },
}
</script>
